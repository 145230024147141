import React from "react";
import tw, { styled, css } from "twin.macro";

export const styles = () => [
  css`
    * {
      ${tw`mb-4`};
    }
    p,
    ul,
    ol {
      ${tw`leading-loose`}
    }

    a {
      ${tw`text-primary hover:underline font-bold duration-500 ease-in-out`}
    }
    ol,
    ul {
      ${tw`sm:ml-8 pl-4 list-disc list-outside`}
    }
    ol {
      ${tw`list-decimal`}
    }
    li {
      ${tw`mb-0`}
    }

    h1 {
      ${tw`font-mazuis text-primary md:text-6xl lg:text-7xl text-5xl italic`}
    }
    h2 {
      ${tw`md:text-2xl text-xl font-bold`}
    }
    h3 {
      ${tw`text-primary text-xl font-bold`}
    }
    h4 {
      ${tw`text-xl font-bold`}
    }

    .custom-blocks__review {
      ${tw`relative flex flex-col items-start mt-4`}
      .stars {
        ${tw`text-primary mb-2 text-4xl`}
      }
      .body {
      }
      .author {
        ${tw`text-sm flex items-center flex-row italic before:(content[""] flex relative w-6 h-px bg-font mr-2)`}
      }
    }

    .custom-blocks__callout {
      ${tw`sm:(my-16 py-12 text-xl) bg-dark relative flex items-center justify-center w-full h-auto px-4 py-6 my-10 text-lg text-center text-white`}
      ${tw`before:(content[''] absolute hidden sm:block top-0 bottom-0 my-auto left-8 w-1/3 h-[calc(100% + 2rem)] bg-primary z-behind shadow-xl)`}
      
			& > p {
        ${tw`max-w-[50rem]`}
      }
      p,
      a {
        ${tw`mb-0`}
      }
      a {
        ${tw`text-primary hover:(text-gold no-underline)`}
      }
    }

    .custom-blocks__case-result {
      ${tw`md:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2 relative grid grid-cols-1 gap-8 pt-6 pb-32 mb-12`}
      * {
        ${tw`mb-0`}
      }
      .single {
        ${tw`flex flex-col items-center justify-start py-10 px-8 relative gap-6 shadow-xl hover:(shadow-2xl scale-105) after:(content[''] bg-primary absolute top-0 left-0 w-full h-4)`}
        .result {
          ${tw`text-primary text-3xl`}
        }
        .case {
          ${tw`text-font font-mazuis -mt-6 text-xl italic font-normal text-center`}
        }
        .decription {
          ${tw`text-font font-normal text-center`}
        }
      }
      .link-wrapper {
        ${tw`absolute bottom-0 bg-black w-full max-w-[25rem] left-0 right-0 justify-center flex flex-col items-center py-4 text-xl mx-auto italic font-mazuis duration-500 ease-in-out  hover:bg-primary`}
        p {
          ${tw`mx-auto font-normal text-center text-white border-b border-white`}
        }
      }
    }

    .custom-blocks__videofaq {
      ${tw`lg:pl-8 xl:pl-0 relative flex flex-col gap-4 mt-8 mb-8 shadow-xl`}

      .question {
        ${tw`bg-dark md:px-16 relative px-6 py-6 text-lg font-bold text-white`}

        &:before {
          ${tw`absolute content["Question"] lg:flex hidden items-center justify-center top-0 text-white left-0 py-2 mt-4 w-28 text-center bg-primary transform font-bold translate-x-[-4.5rem]`}
        }
      }
      .answer {
        &:before {
          ${tw`content["Answer"] lg:flex hidden absolute top-0 items-center justify-center text-white left-0 font-bold py-2 mt-4 w-28 text-center bg-primary translate-x-[-4.5rem]`}
        }

        &:after {
          ${tw`content[""] absolute lg:flex hidden bottom-0 left-0 py-2 mt-4 mb-16 w-px top-0 text-center bg-primary translate-x-[2.5rem]`}
        }
      }
    }

    .custom-block__video-embed {
      ${tw`relative mb-4 text-lg bg-white shadow-lg`}
    }

    .wp-block-image {
      ${tw`mt-0 mb-0`}
      .alignleft {
        ${tw`float-left mb-0 mr-4`}
      }
      .alignright {
        ${tw`float-right mb-0 ml-4`}
      }
      .gatsby-image-wrapper {
        ${tw`mb-0`}
        img {
          ${tw`!object-contain`}
        }
      }
    }

    .wp-block-columns {
      ${tw`flex flex-row gap-0 mb-8`}
      img {
        ${tw`mt-0`}
      }
    }

    .wp-block-image {
      ${tw`max-w-full mt-0`}
    }

    .schema-faq {
      ${tw`xl:pl-8 2xl:pl-0 relative flex flex-col gap-8 mt-6`}

      &-section {
        ${tw`relative flex flex-col shadow-xl`}
      }

      &-question {
        ${tw`bg-dark md:px-16 relative px-6 py-6 text-lg text-white`}
        &:before {
          ${tw`absolute content["Question"] lg:flex hidden shadow-md items-center justify-center top-0 text-white left-0 py-2 mt-4 w-28 text-center bg-primary transform translate-x-[-4.5rem]`}
        }
      }

      &-answer {
        ${tw`md:px-16 md:pb-6 relative px-10 pt-6 pb-12 mt-0 text-lg bg-white`}

        &:before {
          ${tw`content["Answer"] lg:flex hidden absolute top-0 items-center justify-center text-white left-0 font-bold py-2 mt-4 w-28 shadow-md text-center bg-primary transform translate-x-[-4.5rem]`}
        }

        &:after {
          ${tw`content[""] absolute lg:flex hidden bottom-0 left-0 py-2 mt-4 mb-8 w-px top-0 text-center bg-primary transform translate-x-[2.5rem]`}
        }
      }
    }
  `,
];

const Content = ({ article, children, ...rest }) => {
  const Component =
    article === true ? styled.article(styles) : styled.div(styles);
  return typeof children === "string" ? (
    <Component {...rest} dangerouslySetInnerHTML={{ __html: children }} />
  ) : (
    <Component {...rest}>{children}</Component>
  );
};

export default Content;
