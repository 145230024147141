import React from "react";
import { graphql } from "gatsby";
import tw from "twin.macro";
import { Link } from "gatsby";
import { getBreadcrumbs } from "../../lib/functions";

import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import Layout from "../components/Layout";

import Content from "../components/Content";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import SideForm from "../components/SideForm";
import FrontReviews from "../components/FrontReviews";
import YourTeam from "../components/YourTeam";
import CaseResults from "../components/CaseResults";
import Awards from "../components/Awards";
import InnerTitleBlock from "../components/InnerTitleBlock";

const Item = tw(Link)``;

const Sitemap = ({ data, location }) => {
  const {
    page,
    allWpPage: { pages },
    allWpPost: { posts },
  } = data;
  const { title, content, seo, pageSettings } = page;

  const pageList = (pages) =>
    Array.from(pages).map((page, i) => (
      <li key={i}>
        <Item to={page.uri}>{page.title}</Item>
        {page.wpChildren?.nodes?.length > 0 && (
          <ul>{pageList(page.wpChildren.nodes)}</ul>
        )}
        {page.title.toLowerCase() === "blog" && (
          <ul>
            {posts.map(({ title, uri }, i) => (
              <li key={i}>
                <Item to={uri}>{title}</Item>
              </li>
            ))}
          </ul>
        )}
      </li>
    ));

  return (
    <Layout location={location} inner schemaCodes={pageSettings.schemaCodes}>
      <InnerTitleBlock
        header={pageSettings?.header ? pageSettings?.header : title}
        breadcrumbs={getBreadcrumbs(seo)}
      />
      <PageWrapper inner>
        <ContentWrapper>
          <Content>
            <ul>{pageList(pages, posts)}</ul>
          </Content>
        </ContentWrapper>
        <SideWrapper>
          <SideForm />
          <Sidenavs />
        </SideWrapper>
      </PageWrapper>
      <FrontReviews />
      <YourTeam />
      <CaseResults />
      <Awards inner />
    </Layout>
  );
};

export default Sitemap;

export const sitemapQuery = graphql`
  query ($id: String!) {
    allWpPage(
      filter: { parentId: { eq: null } }
      sort: { order: ASC, fields: title }
    ) {
      pages: nodes {
        ...Page
        ...ChildPages
        ...GrandChildPages
      }
    }
    allWpPost {
      posts: nodes {
        ...Post
      }
    }
    page: wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      pageSettings {
        header
        formTitle
        schemaCodes {
          singleSchema
        }
      }
    }
  }
  fragment Page on WpPage {
    title
    uri
  }
  fragment Post on WpPost {
    title
    uri
  }
  fragment ChildPages on WpPage {
    wpChildren {
      nodes {
        ... on WpPage {
          ...Page
        }
      }
    }
  }
  fragment GrandChildPages on WpPage {
    wpChildren {
      nodes {
        ...ChildPages
      }
    }
  }
`;
